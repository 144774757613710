import { useContext } from "react";
import { ExtAContextTypes } from "../types/extendedA";
import ExtAContext from "../contexts/extAContext";

const useExtAConfigs = () => {
  const context = useContext(ExtAContext) as ExtAContextTypes;
  return context;
};

export { useExtAConfigs };
