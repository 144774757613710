import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DashboardContext from "../../helpers/contexts/dashboardContext";
import {
  filterLocationsBySelectedDateRange,
  serializeFilterQueryParams,
} from "../../helpers/utils";
import { setDateRangeWithLocations, setLocationFilter } from "../../state/features/filters/actions";
import { RootState } from "../../state/store";
import { format } from "date-fns";

const PrimaryFilterHelper = ({ children }) => {
  let [params, setParams] = useSearchParams();
  const dispatch = useDispatch();

  let filterParamTimeRange = useMemo(() => params.get("time_range"), [params]);
  const companyLocations = useSelector((state: RootState) => state.locations);

  const applyFilter = ({ type, data }) => {
    switch (type) {
      case "date": {
        let { dateRange, timeRange } = data;
        setParams(
          serializeFilterQueryParams({
            dateRange: data?.dateRange,
            timeRange: data?.timeRange,
          }),
          {
            replace: true,
          }
        );
        let updatedLocations = companyLocations.filter((d) =>
          data?.selectedLocations.includes(d.id)
        );
        let filteredLocations = filterLocationsBySelectedDateRange(
          updatedLocations,
          dateRange
        );
        let locationIds = filteredLocations.map((d) => d.id);

        if (locationIds.length === 0) {
          console.error(
            "No locations available within the selected date range"
          );
          dateRange = [
            format(new Date(), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd"),
          ];
        }
        dispatch(
          setDateRangeWithLocations({
            locationIds,
            selectedDateRange: dateRange,
            selectedTimeRange: timeRange,
          })
        );
        break;
      }
      case "location": {
        let filteredLocations = filterLocationsBySelectedDateRange(
          data?.selectedLocations,
          data?.dateRange
        );
        dispatch(setLocationFilter(filteredLocations.map((loc) => loc.id)));
        break;
      }
      default:
        break;
    }
  };

  return (
    <DashboardContext.Provider value={{ applyFilter }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default PrimaryFilterHelper;
