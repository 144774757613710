import { numFormatter } from "../../../helpers/utils";
import styled from "styled-components";
import { useContext } from "react";
import { WidgetContext } from "../../widgetContext";
import { WidgetSection } from "./widgetSection";
import LastUpdatedFooter from "../../widgetLastUpdatedFooter";
import { format } from "date-fns";

const SmallTwinNumberWidget = () => {
  const widget = useContext(WidgetContext);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  const widgetData = Object.keys(widget.widgetData).length > 0 ?? null;

  const leftSectionConfig = widgetData
    ? widget.widgetData.data.section_1
    : null;
  const rightSectionConfig = widgetData
    ? widget.widgetData.data.section_2
    : null;

  return (
    <Wrapper
      className={`${widget.widgetData.variant} ${
        widget.isTimelessWidget ? "outline" : ""
      }`}
      onClick={handleWidgetOnClick}>
      {widgetData ? (
        <>
          <div className="widget-header">
            <div className="widget-title">{widget.widgetData.title ?? ""}</div>
          </div>
          <div className="widget-info">
            <WidgetSection
              tooltipText={`${leftSectionConfig.pre_text} ${formatNumber(parseFloat(
                leftSectionConfig.value
              ))}`}
              sectionPretext={leftSectionConfig.pre_text}
              sectionText={leftSectionConfig.text}
              sectionDescription={leftSectionConfig.description}
              sectionValue={numFormatter(leftSectionConfig.value)}
            />
            <WidgetSection
              tooltipText={`${rightSectionConfig.pre_text} ${formatNumber(parseFloat(
                rightSectionConfig.value
              ))}`}
              sectionPretext={widget.widgetData.data.section_2.pre_text}
              sectionText={rightSectionConfig.text}
              sectionDescription={rightSectionConfig.description}
              sectionValue={numFormatter(rightSectionConfig.value)}
            />
          </div>
        </>
      ) : null}
      {widget.isTimelessWidget ? (
        <LastUpdatedFooter
          date={format(widget.lastUpdatedTime, "do MMMM yyyy")}
          time={format(widget.lastUpdatedTime, "hh:mm aaa")}
        />
      ) : null}
    </Wrapper>
  );
};

export default SmallTwinNumberWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;

  .widget-info {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &.danger {
    background-color: #cb2e2e;
    color: #fff;
    border-radius: 20px;
    .value {
      color: #fff !important;
    }
  }

  &.outlined, &.outline {
    border-radius: 20px;
    border: 10px solid ${({ theme }) => theme.widget.outline};
    color: #0d7459;
    .value {
      color: #0d7459 !important;
    }
  }

  .widget-header {
    .widget-title {
      display: inline;
      font-size: 26px;
      font-weight: 500;
      opacity: 0.8;
      text-decoration: underline;
    }
  
    .live-badge {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    justify-content: center;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      padding-left: 40px;
      border-left: ${({ theme }) => theme.partition_line};
    }
    .pre-text {
      display: block;
      margin-bottom: 10px;
      height: 10px;
    }
    .value {
      display: block;
      margin-bottom: 10px;
      line-height: 80px;
      font-size: 4rem;
      line-height: 4.5rem;
      font-weight: 800;
      letter-spacing: -2px;
      position: relative;
      color: ${({ theme }) => theme.tooltip.text};
      &.currency:before {
        position: absolute;
        content: attr(data-currency);
        font-size: 20px;
        top: -5px;
        left: 0;
        letter-spacing: 0px;
        font-weight: 500;
        line-height: 1rem;
      }
    }
    .text {
      display: block;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
    .description {
      display: block;
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 23.4px;
    }
  }
`;
