import BasicStackedBarChartWidget from "../components/widgets/analytics/basicStackedBarChart";
import BarChartDrillThroughWidget from "../components/widgets/barChartDrillThroughWidget";
import BarChartWidget from "../components/widgets/barChartWidget";
import BarLineComboWidget from "../components/widgets/barComboWidget";
import CandleStickWidget from "../components/widgets/candleStickWidget";
import DemandForecastWidget from "../components/widgets/demandForecastWidget";
import DonutChartWidget from "../components/widgets/donutChartWidget";
import DonutSwitchWidget from "../components/widgets/donutSwitchWidget";
import DormantChartWidget from "../components/widgets/dormantChartWidget";
import FunnelChartWidget from "../components/widgets/funnelChartWidget";
import GaugeChartWidget from "../components/widgets/gaugeChartWidget";
import GaugeGroupWidget from "../components/widgets/gaugeGroupWidget";
import LineChartWidget from "../components/widgets/lineChartWidget";
import ListWidget from "../components/widgets/listWidget";
import LostOpportunitiesWidget from "../components/widgets/lostOpportunitiesWidget";
import MagicQuadrantWidget from "../components/widgets/magicQuadrantWidget";
import MapBubbleWidget from "../components/widgets/mapBubbleWidget";
import MultiSeriesComboWidget from "../components/widgets/multiSeriesComboWidget";
import MultiSeriesLineChartWidget from "../components/widgets/multiSeriesLineChartWidget";
import MultisetBarChartSwitchWidget from "../components/widgets/multisetBarChartSwitchWidget";
import MultiSetBarChartWidget from "../components/widgets/multiSetBarChartWidget";
import RadialPolarBarWidget from "../components/widgets/radialPolarBarWidget";
import SankeyChartWidget from "../components/widgets/sankeyChartWidget";
import ScatterPlotChartWidget from "../components/widgets/scatterPlotLocationChartWidget";
import SimpleIconWidget from "../components/widgets/simpleIconWidget";
import SmallNumberCostWidget from "../components/widgets/smallNumberCostWidget";
import SmallNumberCountWidget from "../components/widgets/smallNumberCountWidget";
import SmallNumberMultiStatsWidget from "../components/widgets/smallNumberMultiStatsWidget";
import SmallTabbedNumberWidget from "../components/widgets/smallTabbedNumberWidget";
import SmallTwinNumberWidget from "../components/widgets/smallTwinNumberWidget";
import StackedBarChartSwitchWidget from "../components/widgets/stackedBarChartSwitchWidget";
import StackedBarChartWidget from "../components/widgets/stackedBarChartWidget";
import SubRecipeWidget from "../components/widgets/subRecipeWidget";
import SunburstChartWidget from "../components/widgets/sunburstChartWidget";
import TrendsWidget from "../components/widgets/trendsWidget";
import UnknownWidget from "../components/widgets/unknownWidget";
import WaterfallChartWidget from "../components/widgets/waterfallChartWidget";
import BSTableWidget from "../features/businessSummary/components/bsTableWidget";
import HappeningsEventStreamWidget from "../features/happenings/components/happeningsEventStreamWidget";
import { WIDGET_TYPE } from "../types/widgets";

/** `[[w, h], [minW, minH], [maxW, maxH], resizable?, draggable?]` */
export const WidgetsConfigurations = {
  [WIDGET_TYPE.NUM_COUNT_SM]: {
    component: SmallNumberCountWidget,
    layout: [
      [[3, 7], [3, 7], [3, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
    ],
  },
  [WIDGET_TYPE.DONUT_MD]: {
    component: DonutChartWidget,
    layout: [
      [[6, 10], [6, 9], [9, 14], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.BAR_MD]: {
    component: BarChartWidget,
    layout: [
      [[6, 10], [4, 10], [12, 11], true, true],
      [[4, 10], [4, 10], [4, 11], true, true],
      [[3, 10], [3, 10], [4, 11], true, true],
      [[4, 10], [4, 10], [4, 10], false, true],
      [[2, 10], [2, 10], [2, 10], false, true],
    ],
  },
  [WIDGET_TYPE.MAP_BUBBLE_LG]: {
    component: MapBubbleWidget,
    layout: [
      [[12, 9], [6, 9], [12, 11], true, true],
      [[10, 10], [5, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.NUM_DUAL_SM]: {
    component: SmallTwinNumberWidget,
    layout: [
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 6], [4, 6], [4, 6], false, true],
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 7], [4, 7], [4, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
    ],
  },
  [WIDGET_TYPE.NUM_TAB_SM]: {
    component: SmallTabbedNumberWidget,
    layout: [
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 6], [4, 6], [4, 6], false, true],
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 7], [4, 7], [4, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
    ],
  },
  [WIDGET_TYPE.LINE_LG]: {
    component: LineChartWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[5, 10], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.BAR_STACKED_MD]: {
    component: StackedBarChartWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.BAR_LINE_COMBO_LG]: {
    component: BarLineComboWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 10], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], false, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.LINE_MULTISERIES_LG]: {
    component: MultiSeriesLineChartWidget,
    layout: [
      [[12, 10], [6, 9], [14, 9], true, true],
      [[12, 10], [4, 8], [10, 12], true, true],
      [[10, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], false, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.DONUT_DUAL_LG]: {
    component: DonutSwitchWidget,
    layout: [
      [[6, 10], [6, 9], [9, 14], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.BAR_MULTISET_MD]: {
    component: MultiSetBarChartWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], false, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.TRENDS_LG]: {
    component: TrendsWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], false, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.NUM_MULTI_STAT_SM]: {
    component: SmallNumberMultiStatsWidget,
    layout: [
      [[3, 7], [3, 7], [3, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
    ],
  },
  [WIDGET_TYPE.NUM_COST_SM]: {
    component: SmallNumberCostWidget,
    layout: [
      [[3, 7], [3, 7], [3, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
    ],
  },
  [WIDGET_TYPE.SCATTER_PLOT_LG]: {
    component: ScatterPlotChartWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.SANKEY_CHART_LG]: {
    component: SankeyChartWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.RADIAL_POLAR_BAR_LG]: {
    component: RadialPolarBarWidget,
    layout: [
      [[6, 10], [6, 9], [9, 14], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[5, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.GAUGE_CHART_MD]: {
    component: GaugeChartWidget,
    layout: [
      [[3, 8], [3, 8], [4, 10], true, true],
      [[3, 8], [3, 8], [4, 10], true, true],
      [[3, 8], [3, 8], [4, 10], true, true],
      [[4, 8], [4, 8], [4, 10], true, true],
      [[2, 8], [2, 8], [3, 10], true, true],
    ],
  },
  [WIDGET_TYPE.CANDLESTICK_CHART_LG]: {
    component: CandleStickWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.MAGIC_QUADRANT_LG]: {
    component: MagicQuadrantWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.FORECAST_CHART_LG]: {
    component: DemandForecastWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.FUNNEL_CHART_LG]: {
    component: FunnelChartWidget,
    layout: [
      [[6, 10], [4, 9], [12, 12], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.LIST_MD]: {
    component: ListWidget,
    layout: [
      [[6, 10], [4, 9], [12, 12], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.DORMANT_CHART_MD]: {
    component: DormantChartWidget,
    layout: [
      [[6, 10], [4, 9], [11, 12], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.LOST_OPPORTUNITIES_LG]: {
    component: LostOpportunitiesWidget,
    layout: [
      [[12, 12], [6, 9], [9, 14], true, true],
      [[10, 11], [4, 8], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], false, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.MULTI_SERIES_COMBO_CHART]: {
    component: MultiSeriesComboWidget,
    layout: [
      [[12, 10], [4, 9], [11, 12], true, true],
      [[5, 10], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.SUNBURST_CHART_MD]: {
    component: SunburstChartWidget,
    layout: [
      [[6, 10], [6, 9], [9, 14], true, true],
      [[5, 10], [4, 8], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.BAR_MULTISET_SWITCH_MD]: {
    component: MultisetBarChartSwitchWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.WATERFALL_CHART_LG]: {
    component: WaterfallChartWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 10], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], false, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
  [WIDGET_TYPE.BAR_STACKED_SWITCH_MD]: {
    component: StackedBarChartSwitchWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.GAUGE_GROUP_LG]: {
    component: GaugeGroupWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.STACKED_BAR_CHART]: {
    component: BasicStackedBarChartWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.BS_TABLE_WIDGET]: {
    component: BSTableWidget,
    layout: [
      [[1, 10], [1, 9], [2, 12], false, false],
      [[1, 10], [1, 8], [2, 12], false, false],
      [[1, 10], [undefined, undefined], [undefined, undefined], false, false],
      [[1, 10], [undefined, undefined], [undefined, undefined], false, false],
      [[1, 10], [undefined, undefined], [undefined, undefined], false, false],
    ],
  },
  [WIDGET_TYPE.SIMPLE_ICON_WIDGET]: {
    component: SimpleIconWidget,
    layout: [
      [[3, 7], [3, 7], [3, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
      [[3, 7], [3, 7], [3, 7], false, true],
    ],
  },
  [WIDGET_TYPE.SUB_RECIPE_WIDGET]: {
    component: SubRecipeWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[10, 9], [4, 9], [10, 12], true, true],
      [[10, 3], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 4], [undefined, undefined], [undefined, undefined], true, true],
      [[10, 2], [undefined, undefined], [undefined, undefined], true, true],
    ],
  },
  ["UNKNOWN_TYPE"]: {
    component: UnknownWidget,
    layout: [
      [[3, 7], [3, 7], [undefined, undefined], true, true],
      [[2, 6], [2, 6], [undefined, undefined], true, true],
      [[3, 7], [2, 7], [undefined, undefined], true, true],
      [[3, 7], [3, 7], [undefined, undefined], true, true],
      [[2, 7], [2, 7], [undefined, undefined], true, true],
    ],
  },
  [WIDGET_TYPE.HAPPENINGS_EVENT_STREAM]: {
    component: HappeningsEventStreamWidget,
    layout: [
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 6], [4, 6], [4, 6], false, true],
      [[6, 7], [6, 7], [6, 7], false, true],
      [[4, 7], [4, 7], [4, 7], false, true],
      [[2, 7], [2, 7], [2, 7], false, true],
    ],
  },
  [WIDGET_TYPE.CUSTOM_BAR_CHART_DRILLTHROUGH]: {
    component: BarChartDrillThroughWidget,
    layout: [
      [[12, 10], [4, 9], [12, 11], true, true],
      [[5, 10], [4, 9], [10, 12], true, true],
      [[3, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[4, 10], [undefined, undefined], [undefined, undefined], true, true],
      [[2, 10], [undefined, undefined], [undefined, undefined], false, true],
    ],
  },
};
