import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RiFilter2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { filterOperations } from "../../state/features/filters";
import { RootState, dispatchAction } from "../../state/store";
import { removeDuplicateArrayItems } from "../../helpers/utils";
import { IoIosCloseCircle } from "react-icons/io";
import { clearSecondaryFilterFocus } from "../../state/features/analytics/actions";

interface IFilterDropdownItemProps {
  key: number;
  filterItem: any;
  handleFilterItemSelection: any;
  resetButtonRef: any;
  selectedFilters: any;
  setIsScrolling: any;
  isScrolling: boolean;
}

export const FilterDropdownItem = (props: IFilterDropdownItemProps) => {
  const [filterItemsList, setFilterItemsList] = useState<any[]>([]);
  const [initialRender, setInitialRender] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { mode: theme } = useSelector((state: RootState) => state.theme);

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );
  const multiSelectRef = useRef(null);
  const filterLabelRef = useRef(null);

  const filterToFocus = useSelector(
    (state: RootState) => state.analytics.focusFilter.widget
  );

  useEffect(() => {
    if (!isMenuOpen && filterToFocus === props.filterItem.filterKey) {
      setTimeout(() => {
        filterLabelRef?.current?.scrollIntoView({ behaviour: "smooth" });
        setTimeout(() => {
          multiSelectRef?.current?.focus();
        }, 500);
      }, 500);
    }
    if (isMenuOpen && filterToFocus === props.filterItem.filterKey) {
      dispatchAction(clearSecondaryFilterFocus());
    }
  }, [filterToFocus, isMenuOpen]);

  useEffect(() => {
    if (multiSelectRef.current) {
      multiSelectRef.current.clearValue();
    }
  }, [widgetFilters.length]);

  const highlighedVals = useMemo(() => {
    if (props.selectedFilters.length) {
      let findFilter = props.selectedFilters.find(
        (d) => d.key === props.filterItem.filterKey
      );
      if (findFilter) {
        return findFilter.data.map((d) => ({
          ...d,
          label: d.name,
          value: d.id,
          data_value: d.value,
        }));
      }
      return [];
    }
    return [];
  }, [props.selectedFilters]);

  useEffect(() => {
    if (props.filterItem) {
      let multiSelectOptions = props.filterItem.options.map((f) => ({
        ...f,
        label: f.name,
        value: f.id,
        data_value: f.value,
      }));
      setFilterItemsList(removeDuplicateArrayItems(multiSelectOptions));
    }
    if (props.resetButtonRef.current) {
      props.resetButtonRef.current.addEventListener("click", () => {
        multiSelectRef.current.clearValue();
      });
    }
    return () => {
      if (props.resetButtonRef.current) {
        props.resetButtonRef.current.removeEventListener();
      }
    };
  }, [props.filterItem]);

  const handleItemSelect = (optionData) => {
    setIsMenuOpen(false);
    multiSelectRef?.current?.blur();
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    let submitData = {
      key: props.filterItem.filterKey,
      name: props.filterItem.name,
      optionData: optionData,
    };
    props.handleFilterItemSelection(submitData);
  };

  const customStyle = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      fontSize: "1rem",
      boxShadow: "rgba(0, 0, 0, 0.2) 5px 5px 10px",
      border: "1px solid rgb(222, 222, 222)",
      borderRadius: "10px",
      maxHeight: "200px",
      width: "250px",
      overflowY: "scroll",
      position: "absolute",
      top: "-40px",
      left: "30px",
      background: theme == "dark" ? "rgb(33, 33, 33)" : "#fff",
      color: theme == "dark" ? "#fff" : "#000",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      width: "250px",
      borderRadius: "10px",
      background: theme == "dark" ? "rgb(33, 33, 33)" : "#fff",
    }),
    option: (base) => ({
      ...base,
      cursor: "pointer",
      background: theme == "dark" ? "rgb(33, 33, 33)" : "#fff",
      "&:hover": {
        color: "#fff",
        background: "#3aa98d",
      },
    }),
  };

  useEffect(() => {
    if (props.isScrolling) {
      setIsMenuOpen(false);
      props.setIsScrolling(false);
      multiSelectRef?.current?.blur();
    }
  }, [props.isScrolling]);

  return (
    <>
      <span className="filter-label" ref={filterLabelRef}>
        {props.filterItem.name}
      </span>
      <Select
        classNamePrefix="custom-filter"
        className="custom-select"
        options={filterItemsList}
        isMulti
        onChange={handleItemSelect}
        value={highlighedVals}
        ref={multiSelectRef}
        menuIsOpen={isMenuOpen}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
        }}
        menuPortalTarget={document.body}
        styles={customStyle}
        placeholder={"Search here..."}
        onFocus={() => {
          setIsMenuOpen(true);
          props.setIsScrolling(false);
        }}
        onBlur={() => {
          setIsMenuOpen(false);
          props.setIsScrolling(false);
        }}
      />
    </>
  );
};

const SecondaryFilterDropdown = () => {
  const { filterList } = useSelector((state: RootState) => state.analytics);
  const { widgets } = useSelector((state: RootState) => state.filters);
  const focusFilter = useSelector(
    (state: RootState) => state.analytics.focusFilter.widget
  );

  const [selectedFilters, setSelectedFilters] = useState<
    {
      name: string;
      key: string;
      data: { id: number; name: string; value: number | string }[];
    }[]
  >([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const dispatch = useDispatch();

  const handleFilterItemSelection = (item) => {
    setSelectedFilters((setFilters) => {
      let updatedFilter = setFilters.length ? [...setFilters] : [];
      let index = updatedFilter.findIndex((w) => w.key === item.key);
      if (index === -1) {
        updatedFilter.push({
          name: item.name,
          key: item.key,
          data: item.optionData,
        });
      } else {
        updatedFilter[index] = {
          ...updatedFilter[index],
          data: item.optionData,
        };
      }
      updatedFilter = updatedFilter.filter((i) => i.data.length);
      return updatedFilter;
    });
  };

  useEffect(() => {
    setSelectedFilters(widgets);
  }, [widgets]);

  const resetButtonRef = useRef();

  const resetFilters = () => {
    setSelectedFilters([]);
    dispatch(filterOperations.clearWidgetFilter());
    setShowFilters(false);
  };

  const applySelectedFilters = () => {
    if (selectedFilters.length) {
      let filters = selectedFilters.filter((i) => i.data.length);
      dispatch(filterOperations.setDropdownFilters(filters));
      setShowFilters(false);
    }
  };

  const submitButtonDisabled = useMemo(() => {
    if (!selectedFilters.length) {
      return true;
    }
    if (selectedFilters.length != widgets.length) {
      return false;
    }
    for (let i = 0; i < selectedFilters.length; i++) {
      let filter = selectedFilters[i];
      const currentFilters = widgets.find((j) => j.key == filter.key);
      if (filter.data.length != currentFilters?.data?.length) {
        return false;
      }
      const currentFilterIds = currentFilters?.data?.map((j) => j.id);
      const dataDiff = filter.data.filter(
        (j) => !currentFilterIds?.includes(j.id)
      );
      if (dataDiff?.length) {
        return false;
      }
    }
    return true;
  }, [selectedFilters, widgets]);

  const resetButtonDisabled = useMemo(() => {
    if (widgets.length) {
      return false;
    }
    return true;
  }, [selectedFilters]);

  const openModal = () => {
    setShowFilters(true);
    setSelectedFilters(widgets);
  };

  const closeModal = () => {
    setShowFilters(false);
    setSelectedFilters(widgets);
  };

  useEffect(() => {
    if (focusFilter) {
      openModal();
    }
  }, [focusFilter]);

  const noOfSecondaryFiltersApplied = widgets.length;

  return (
    <Wrapper className="secondary-filter-control">
      <div className="filter-icon" onClick={openModal}>
        <RiFilter2Fill color="#2B7BC5" />
        {noOfSecondaryFiltersApplied > 0 ? (
          <span className="current-applied-filter-number">
            {noOfSecondaryFiltersApplied}
          </span>
        ) : null}
      </div>
      <CustomModal show={showFilters} onHide={closeModal} size="lg" centered>
        <ModalContainer>
          <div className="modal-close-btn" onClick={closeModal}>
            <IoIosCloseCircle
              fontSize="50px"
              className="close-icon"
              fill="#212121"
            />
          </div>
          <div className="filter-modal-body">
            <p className="section-heading">Filter your data</p>
            <div
              className="filter-modal-items"
              onScroll={() => setIsScrolling(true)}>
              {filterList.length
                ? filterList.map((filterItem, index: number) => (
                    <div className="item" key={index}>
                      <FilterDropdownItem
                        key={filterItem.filterKey}
                        filterItem={filterItem}
                        handleFilterItemSelection={handleFilterItemSelection}
                        resetButtonRef={resetButtonRef}
                        selectedFilters={selectedFilters}
                        setIsScrolling={setIsScrolling}
                        isScrolling={isScrolling}
                      />
                    </div>
                  ))
                : null}
            </div>
            <div className="section-footer">
              <button
                className="btn btn-outline btn-reset"
                onClick={resetFilters}
                disabled={resetButtonDisabled}>
                Reset
              </button>
              <button
                className="btn btn-primary btn-outline btn-confirm"
                onClick={applySelectedFilters}
                disabled={submitButtonDisabled}>
                Apply
              </button>
            </div>
          </div>
        </ModalContainer>
      </CustomModal>
    </Wrapper>
  );
};

export default SecondaryFilterDropdown;

const Wrapper = styled.div`
  .filter-icon {
    > svg {
      font-size: 20px;
    }
    background-color: ${({ theme }) => theme.widget.background};
    border-color: ${({ theme }) => theme.widget.background};
    color: ${({ theme }) => theme.text};
    border-radius: 8px;
    padding: 9px;
    margin: 0 15px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .current-applied-filter-number {
      position: relative;
      font-size: 11px;
      background: red;
      display: flex;
      justify-content: center;
      align-items: end;
      border-radius: 10px;
      width: 15px;
      height: 15px;
      color: #ffffff;
      left: -10px;
      top: -10px;
    }
  }
  @media screen and (max-width: 1280px) {
    .filter-icon {
      margin: 0px 5px 0px 5px;
      padding: 6px;
      > svg {
        font-size: 24px;
      }
    }
  }
`;

const CustomModal = styled(Modal)`
  .modal-content {
    background: ${({ theme }) => theme.widget.background};
    box-sizing: border-box;
    box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: 1px solid #dedede;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  .modal-close-btn {
    cursor: pointer;
    position: absolute;
    right: -18px;
    top: -18px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    background-color: ${({ theme }) => theme.widget.background};
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      fill: ${({ theme }) => theme.text};
    }
  }
  .filter-modal-body {
    padding: 20px;
    .section-heading {
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
    }
    .filter-modal-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 80px;
      gap: 20px;
      height: 300px;
      overflow: scroll;
      padding: 0.5rem 0;
      > .item {
        font-size: 16px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        position: relative;
        padding: 0.5rem 0;
        > span.filter-label {
          font-weight: 600;
          font-size: 14px;
          display: block;
          position: absolute;
          padding: 0 0.5rem;
          top: -0.8rem;
          z-index: 5;
          left: 0.5rem;
          background: ${({ theme }) => theme.dropdown.background_color};
        }
        .custom-select {
          .custom-filter__control {
            height: 62px;
            box-shadow: none;
            border: none;
            background: ${({ theme }) => theme.dropdown.background_color};
            color: ${({ theme }) => theme.text};
            &.custom-filter__control--is-focused {
              border-color: #0d8a6a;
            }
            .custom-filter__value-container {
              cursor: text;
              font-weight: 400;
              height: 100%;
              overflow: scroll;
              align-items: flex-start;
              .custom-filter__multi-value {
                background: #198754;
                color: #fff;
                .custom-filter__multi-value__label {
                  color: #fff;
                }
                .custom-filter__multi-value__remove {
                  background: #146c43;
                }
              }
            }
            .custom-filter__indicators {
              cursor: pointer;
            }
          }
        }
      }
    }
    .section-footer {
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
      .btn {
        padding: 5px 0;
        width: 12rem;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        &.btn-reset {
          border-color: #8a8a8a;
          color: ${({ theme }) => theme.text};
          &:focus {
            outline: none;
          }
          margin-right: 10px;
        }
        &.btn-primary {
          color: #fff;
          background-color: ${({ theme }) => theme.button.primary_color};
          border-color: ${({ theme }) => theme.button.primary_color};
        }
      }
    }
  }
`;
