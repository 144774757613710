import { Modal } from "react-bootstrap";
import CloseButton from "../reportDialog/closeButton";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import styled from "styled-components";

/** Work In Progress */
const CalculatedFieldDg = ({ show = false }) => {
  const { calcFieldDialogHandler } = useExtAConfigs();

  return (
    <Modal show={show} dialogClassName="ext-a-calculated-field">
      <Modal.Header>
        <Modal.Title>Create Calculated Field</Modal.Title>
        <CloseButton onClick={calcFieldDialogHandler.hideDialog} />
      </Modal.Header>
      <Modal.Body>
        <Wrapper>
          <p className="field-name">Field Name</p>
          <input type="text" placeholder="Enter field name" />
          <p>Formula</p>
          <textarea name="" id="" className="formula-area"></textarea>
        </Wrapper>
      </Modal.Body>
    </Modal>
  );
};

export default CalculatedFieldDg;

const Wrapper = styled.div`
  height: 400px;
  font-size: 18px;
  input {
    width: 100%;
  }
  .formula-area {
    width: 100%;
    height: 100px;
  }
`;
