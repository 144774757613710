import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ActiveFilterSection from "../../../components/activeFilterSection";
import { ComparisonProvider } from "../../../components/comparison/comparisonProvider";
import DashboardDataContext from "../../../helpers/contexts/dashboardDataContext";
import { useFilterNavigate } from "../../../helpers/hooks/useFilterNavigate";
import { getAnalyticsMetaWidgets } from "../../../helpers/utils";
import { clearFilterList } from "../../../state/features/analytics/actions";
import { RootState } from "../../../state/store";
import ComparisonContainer from "../comparison";
import ReportsLoaderModal from "../report/reportsLoader";
import AnalyticsWidgetsGrid from "./analyticsWidgetsGrid";
import { useAnalyticsFilters } from "../../../helpers/hooks/useAnalyticsFilters";
import LoadAnalyticsFilterData from "./loadAnalyticsFilterData";
import { ENTITY_LOCK_TYPE } from "../../../types/visibility";
import OverviewHeader from "../../../components/overviewHeader";
import ExtendedAProvider from "../../../features/extendedAnalytics/components/extendedAProvider";
import ExtAProvider from "../../../features/extendedASSRM/components/extAProvider";

const AnalyticsLayout = () => {
  const [analyticsWidgets, setAnalyticsWidgets] = useState<any[]>([]);
  const [dashboardWidgetInfo, setDashboardWidgetInfo] = useState<any>(null);
  const { comparison } = useSelector((state: RootState) => state);
  const [freezeDateRangeDropdown, setFreezeDateRangeDropdown] = useState(false);
  const [disableFiltersFromWidgets, setDisableFiltersFromWidgets] =
    useState(false);
  const [comparisonMarketingContent, setComparisonMarketingContent] =
    useState(null);
  const [showComparisonMarketingBanner, setShowComparisonMarketingBanner] =
    useState(false);
  const [isComparisonEnabled, setIsComparisonEnabled] = useState(false);
  const {
    analyticsFiltersToFetch,
    setAnalyticsFilterUrls,
    shouldFetchFilterData,
  } = useAnalyticsFilters();
  const routeMatch = useParams();

  const dispatch = useDispatch();
  const layoutRef = useRef(null);

  const {
    dashboardWidgets,
    dashboardInfo,
    refreshDashboardData,
    shouldEnableDashboardFetch,
    isDashboardDataLoading,
  } = useContext(DashboardDataContext);

  const navigateTo = useFilterNavigate();

  useEffect(() => {
    dispatch(clearFilterList());
  }, []);

  useEffect(() => {
    if (dashboardWidgets.length > 0 && dashboardInfo) {
      // if coming from DashboardLayout
      shouldEnableDashboardFetch(false);
      let dashboardWidget = dashboardWidgets.find(
        (d) => d.url === routeMatch.widget
      );
      if(dashboardWidget) {
        if ("is_timeless_widget" in dashboardWidget) {
          setFreezeDateRangeDropdown(dashboardWidget.is_timeless_widget);
        }
        if ("analytics_filters" in dashboardWidget) {
          setDisableFiltersFromWidgets(analyticsWidgets.length > 0);
          // TODO - Pass this boolean to all widgets
          if (dashboardWidget.analytics_filters.length > 0) {
            setAnalyticsFilterUrls(dashboardWidget.analytics_filters);
          }
        }
        if ("is_comparison_enabled" in dashboardWidget) {
          setIsComparisonEnabled(dashboardWidget.is_comparison_enabled)
        }
        if("comparison_visibility" in dashboardWidget) {
          const comparisonVisibilityType = dashboardWidget.comparison_visibility;
          if (comparisonVisibilityType == ENTITY_LOCK_TYPE.MARKETING_MESSAGE) {
            setComparisonMarketingContent({
              marketing_html_url: dashboardWidget.comparison_marketing_html_url,
            });
          }
        }
        setDashboardWidgetInfo(dashboardWidget);
        if (dashboardWidget) {
          let metaWidgets = getAnalyticsMetaWidgets(dashboardWidget.widgets);
          setAnalyticsWidgets(metaWidgets);
        }
      } else {
        navigateTo(`/${dashboardInfo.api_path}`);
      }
    } else {
      // if directly rendered; i.e directly accessed from url
      refreshDashboardData();
    }
  }, [dashboardWidgets.length]);

  const handleShowReport = () => {
    navigateTo(`/${dashboardInfo.name}/${routeMatch.widget}/report`);
  };

  const handleShowComparisonMarketingContent = () => {
    setShowComparisonMarketingBanner(true);
  }
  const handleHideComparisonMarketingContent = () => {
    setShowComparisonMarketingBanner(false);
  }

  return (
    <LayoutWrapper ref={layoutRef}>
      {isDashboardDataLoading ? (
        <ReportsLoaderModal />
      ) : (
        <ExtendedAProvider>
          <ExtAProvider>
            <ComparisonProvider
              dashboardName={
                dashboardInfo && dashboardWidgetInfo
                  ? dashboardInfo?.display_name
                  : ""
              }
              analyticsName={
                dashboardInfo && dashboardWidgetInfo
                  ? dashboardWidgetInfo.widget.title ?? ""
                  : ""
              }
              isComparisonEnabled={isComparisonEnabled}
              comparisonDimensions={dashboardWidgetInfo?.comparison_dimensions}
              comparisonMarketingContent={comparisonMarketingContent}
              onHideComparisonMarketingContent={
                handleHideComparisonMarketingContent
              }
              onShowComparisonMarketingContent={
                handleShowComparisonMarketingContent
              }
              setComparisonMarketingContent={setComparisonMarketingContent}
              showComparisonMarketingContent={showComparisonMarketingBanner}
            >
              {dashboardInfo && dashboardWidgetInfo ? (
                <>
                  <OverviewHeader
                    dashboardTitle={dashboardInfo?.display_name}
                    analyticsDashboardTitle={
                      dashboardWidgetInfo.widget.title ?? ""
                    }
                    isComparisonEnabled={isComparisonEnabled}
                    comparisonDimensions={
                      dashboardWidgetInfo?.comparison_dimensions
                    }
                    shouldFreezeDateRangeSelection={freezeDateRangeDropdown}
                    shouldShowComparisonResults={
                      comparison?.showComparisonResults
                    }
                  />
                  <ActiveFilterSection />
                </>
              ) : null}
              {comparison.showComparisonResults ? (
                <ComparisonContainer />
              ) : (
                <>
                  {analyticsFiltersToFetch.length
                    ? analyticsFiltersToFetch.map((url) => (
                        <LoadAnalyticsFilterData
                          key={url}
                          dashboard={routeMatch.dashboard}
                          widget={routeMatch.widget}
                          filterUrl={url}
                          shouldFetchFilterData={shouldFetchFilterData}
                        />
                      ))
                    : null}
                  {analyticsWidgets.length ? (
                    <>
                      <AnalyticsWidgetsGrid
                        analyticsWidgets={analyticsWidgets}
                        dashboard={routeMatch.dashboard}
                        dashboardWidget={routeMatch.widget}
                      />
                      <PageFooter>
                        {dashboardWidgetInfo &&
                        dashboardWidgetInfo.is_report_enabled ? (
                          <button
                            className="btn btn-primary"
                            onClick={handleShowReport}
                          >
                            View Details
                          </button>
                        ) : null}
                      </PageFooter>
                    </>
                  ) : null}
                </>
              )}
            </ComparisonProvider>
          </ExtAProvider>
        </ExtendedAProvider>
      )}
    </LayoutWrapper>
  );
};

export default AnalyticsLayout;

const LayoutWrapper = styled.div`
  margin-top: 240px;
  padding-bottom: 200px;
`;

const PageFooter = styled.div`
  margin: 5rem 58px;
  padding: 1rem 0;
  border-top: 1px solid #d2d2d2;
  text-align: right;
`;
