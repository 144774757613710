import styled from "styled-components";
import { ReactElement, useContext, useMemo } from "react";
import { SubRDataContext } from "../dataProvider";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import {
  ORDER_STATUS_TIMESTAMP_KEY,
  createTimelineFromOrderType,
} from "../utils";
import { IconType } from "react-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatDuration } from "../../../../containers/layout/report/utils";
import {
  formatDateWithTimezoneOffset,
  generateRandomId,
} from "../../../../helpers/utils";

export default function OrderTimeline() {
  const data = useContext(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);
  const tzOffset = useSelector(
    (state: RootState) => state?.company?.company_details?.time_zone_offset
  );

  const timeline = useMemo(() => {
    let orderStatusTimeline:
      | {
          title: string;
          orderStatus: ORDER_STATUS_TIMESTAMP_KEY;
          time: number;
          icon: ReactElement<IconType>;
        }[]
      | any[] = [];
    let timeDiff: any[] = [];
    let totalTime = 0;
    if (data.timelineData) {
      let timelineConfig = createTimelineFromOrderType({
        orderType: data.order_type,
        timelineData: data.timelineData,
        orderStatus: data.order_status,
      });
      orderStatusTimeline = timelineConfig ? timelineConfig.orderStatuses : [];
      orderStatusTimeline = orderStatusTimeline.filter((i) => i.time);
      for (let i = 0; i < orderStatusTimeline.length - 1; i++) {
        let difference =
          orderStatusTimeline[i + 1].time - orderStatusTimeline[i].time;
        timeDiff.push(difference);
        totalTime += difference;
      }
      timeDiff.push(0);
    }
    return { orderStatusTimeline, timeDiff, totalTime };
  }, [data.timelineData, data.order_type, data.order_status]);

  const getPercentage = (time: number) => {
    if (time == 0 && timeline.totalTime == 0) {
      return 100;
    }
    if (time == 0) {
      return 0;
    }
    const diffPercent = (time * 100) / timeline.totalTime;
    if (diffPercent < 20) {
      return 20;
    }
    return diffPercent;
  };

  return (
    <Container>
      {data.isOrderSummaryDataLoading && !data.timelineData ? (
        <div className="loader-container">
          <ContentLoader
            height={90}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ) : (
        <div className="line-wrapper">
          {timeline.orderStatusTimeline.length ? (
            <div>
              {timeline.orderStatusTimeline.map((status, index) => {
                let width = getPercentage(timeline.timeDiff[index]);
                if (
                  index == timeline.orderStatusTimeline.length - 1 &&
                  timeline.orderStatusTimeline.length > 1
                ) {
                  width = 0;
                }
                return (
                  <div
                    className="line"
                    style={{
                      width: `${width}%`,
                    }}
                    key={generateRandomId()}
                  >
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={
                        <PopoverWrapper className="tooltip">
                          <span>{status.title}</span>
                          {status.orderStatus ==
                          ORDER_STATUS_TIMESTAMP_KEY.CREATED_AT ? null : (
                            <span>
                              {formatDuration(
                                parseInt(timeline.timeDiff[index - 1])
                              )}
                            </span>
                          )}
                        </PopoverWrapper>
                      }
                    >
                      <div className="status-icons">
                        <span>{status.icon}</span>
                        <span className="text">
                          {index == 0 ? (
                            <>
                              {formatDateWithTimezoneOffset(
                                status.time,
                                tzOffset,
                                "hh:mm aa"
                              )}
                            </>
                          ) : (
                            <>
                              <span>
                                {formatDuration(
                                  parseInt(timeline.timeDiff[index - 1])
                                )
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                              </span>
                              <span className="sub-text">
                                {formatDateWithTimezoneOffset(
                                  status.time,
                                  tzOffset,
                                  "hh:mm aa"
                                )}
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </div>
      )}
    </Container>
  );
}

const PopoverWrapper = styled(Popover)`
  padding: 0.5rem;
  opacity: 1 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: #000;
`;

const Container = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  height: 90px;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  flex-shrink: 0;
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  width: 100%;
  .loader-container {
    width: 100%;
  }
  .line-wrapper {
    padding: 0 2.5rem;
    width: 100%;
    transform: translateY(35%);
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      .line {
        position: relative;
        height: 6px;
        background: #9c9fa6;
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
        .status-icons {
          position: absolute;
          left: 0;
          transform: translate(-50%, -18px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 0.25rem;
          width: 50px;
          height: 100%;
          .icon {
            border: 1px solid #9c9fa6;
            background: #afb6bb;
            color: #ffffff;
            border-radius: 20px;
            padding: 0.25rem;
          }
          .text {
            max-width: 100px;
            text-align: center;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .sub-text {
              color: #828282;
              font-size: 8px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          svg {
            color: #000000;
          }
        }
      }
    }
  }
  .no-data {
    font-size: 16px;
    opacity: 0.5;
  }
`;
