import { useContext } from "react";
import styled from "styled-components";
import { SubRDataContext } from "../dataProvider";
import { format } from "date-fns";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { formatDateWithTimezoneOffset } from "../../../../helpers/utils";

export default function Modification() {
  const data = useContext(SubRDataContext);
  const theme = useSelector((state: RootState) => state.theme.mode);
  const tzOffset = useSelector(
    (state: RootState) => state?.company?.company_details?.time_zone_offset
  );

  return (
    <Container>
      <span>Modifications</span>
      {data.isOrderSummaryDataLoading && !data.modification ? (
        <div className="loader-container">
          <ContentLoader
            height={130}
            speed={3}
            backgroundColor={theme === "light" ? "#f3f3f3" : "#2a2a2a"}
            foregroundColor={theme === "light" ? "#ecebeb" : "#1e1e1e"}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ) : (
        <>
          {data.modification && data.modification.length ? (
            <div className="table-data">
              <table>
                <thead>
                  <tr>
                    <th>Modified Item</th>
                    <th>Modification Type</th>
                    <th>Modified By</th>
                    <th>Date & Time</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {data.modification.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td>{i.item}</td>
                        <td>{i.modification_type}</td>
                        <td>{i.staff_name}</td>
                        <td>
                          {formatDateWithTimezoneOffset(
                            i.modified_at,
                            tzOffset,
                            "dd-LLL-yyyy pp"
                          )}
                        </td>
                        <td>{i.modification_reason}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5rem;
  margin-top: 0.25rem;
  width: 100%;
  background: ${({ theme }) => theme.widget.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 4px);
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
  }
  .table-data {
    margin-top: 0.5rem;
    height: 125px;
    overflow: scroll;
    table {
      width: 100%;
      border-radius: 0.5rem;
      overflow: hidden;
      thead {
        background-color: ${({ theme }) => theme.body};
        opacity: 0.5;
      }
      tbody {
        border-top: 1px solid #e6dfdf;
      }
      tr {
        th,
        td {
          padding: 0.5rem 0;
          font-size: 10px;
          font-weight: 400;
          line-height: normal;
          text-align: center;
        }
        td {
          font-size: 11px;
        }
      }
    }
  }
  .no-data {
    font-size: 16px;
    opacity: 0.5;
    height: 125px;
    margin-top: 0.5rem;
    border: 1px solid #e6dfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
`;
