import { Modal } from "react-bootstrap";
import CloseButton from "../reportDialog/closeButton";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import styled from "styled-components";
import { BsFillTrashFill } from "react-icons/bs";

const SavedViewsDialog = ({ show = false }) => {
  const { savedVwsDialogHandler, viewControls, views } = useExtAConfigs();

  return (
    <Modal show={show} dialogClassName="ext-a-saved-views">
      <Modal.Header>
        <Modal.Title>Saved Views</Modal.Title>
        <CloseButton onClick={savedVwsDialogHandler.hideDialog} />
      </Modal.Header>
      <Modal.Body>
        <ListWrapper>
          {views.savedViews.map((l) => {
            return (
              <div key={l.id} className="list-item">
                <div className="item-name">{l.name}</div>
                <div className="item-actions">
                  <button
                    className="btn item-action-btn"
                    style={{ background: "#565656" }}
                    onClick={() => viewControls.loadSelectedView(l.data)}
                  >
                    Load
                  </button>
                  <button
                    className="btn item-action-btn"
                    style={{ background: "#ed7846" }}
                    onClick={() => viewControls.overwriteView(l.id)}
                  >
                    Overwrite
                  </button>
                  <button
                    className="btn item-action-btn"
                    style={{ background: "#ed4646" }}
                    onClick={() => viewControls.deleteView(l.id)}
                  >
                    <BsFillTrashFill />
                  </button>
                </div>
              </div>
            );
          })}
        </ListWrapper>
      </Modal.Body>
    </Modal>
  );
};

export default SavedViewsDialog;

const ListWrapper = styled.div`
  .list-item {
    background-color: ${({ theme }) => theme.modal.background};;
    display: flex;
    align-items: center;
    .item-name {
      flex: 1;
      font-size: 18px;
    }
    .item-actions {
      .item-action-btn {
        color: #fff;
        margin: 5px;
      }
    }
    border: 1px solid #dedede;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
  }
  height: 400px;
  overflow-y: scroll;
`;
