import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../state/store";
import { ColDef } from "ag-grid-charts-enterprise";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import { themeQuartz } from "ag-grid-community";

const PivotTable = () => {
  const theme = useSelector((state: RootState) => state.theme.mode);

  const { pivotTableRef, serverSideDataSource, reportConfigs, gridState } =
    useExtAConfigs();
  const gridConfig = reportConfigs.configs;

  const columnDefs = useMemo(() => {
    if (gridConfig) {
      return gridConfig?.config.fields;
    }
    return [];
  }, [gridConfig]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 130,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      resizable: true,
      lockPinned: true,
    };
  }, []);

  const reportTheme = useMemo(
    () => ({
      light: themeQuartz.withParams({
        accentColor: "#0D8A6A",
        browserColorScheme: "light",
        headerFontSize: 14,
      }),
      dark: themeQuartz.withParams({
        accentColor: "#0D8A6A",
        backgroundColor: "#212121",
        browserColorScheme: "dark",
        foregroundColor: "#FFFFFF",
      }),
    }),
    []
  );

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  return (
    <Wrapper style={containerStyle}>
      <div style={gridStyle}>
        <AgGridReact
          ref={pivotTableRef}
          theme={theme === "dark" ? reportTheme.dark : reportTheme.light}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={{
            allowDragFromColumnsToolPanel: false,
          }}
          enableCharts={true}
          cellSelection
          rowGroupPanelShow="onlyWhenGrouping"
          groupDisplayType="groupRows"
          sideBar={["columns"]}
          grandTotalRow={"bottom"}
          pivotMode
          rowModelType="serverSide"
          serverSideDatasource={serverSideDataSource}
          // initialState={gridState.initialState} // TODO: implement grid state
          // onGridPreDestroyed={gridState.onGridPreDestroyed}
          // onStateUpdated={gridState.onStateUpdated}
        />
      </div>
    </Wrapper>
  );
};

export default PivotTable;

const Wrapper = styled.div`
  .ag-pivot-mode-panel {
    display: none;
  }
`;
