import { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import LocationPin from "../../images/icons/locationPin.svg";
import { RootState, dispatchAction } from "../../state/store";
import { LocationFilterProvider } from "./context";
import MultiSelectMenu from "./multiSelectMenu";
import { filterLocationsBySelectedDateRange } from "../../helpers/utils";
import { clearLocationFilterFocus } from "../../state/features/analytics/actions";

const LocationFilterDropdown = () => {
  const appliedLocationFilters = useSelector(
    (state: RootState) => state.filters.locations
  );

  const companyLocations = useSelector((state: RootState) => state.locations);
  const selectedDateRange = useSelector(
    (state: RootState) => state.filters.dates
  );
  const focusLocationFilters = useSelector(
    (state: RootState) => state.analytics.focusFilter.location
  );

  const locationsValidInSelectedDateRange = useMemo(() => {
    return filterLocationsBySelectedDateRange(
      companyLocations,
      selectedDateRange
    );
  }, [JSON.stringify(selectedDateRange), JSON.stringify(companyLocations)]);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleRef = useRef(null);

  let toggleTitle = useMemo(() => {
    if (locationsValidInSelectedDateRange.length > 0) {
      if (
        appliedLocationFilters.length ===
        locationsValidInSelectedDateRange.length
      ) {
        return "All Locations";
      } else if (appliedLocationFilters.length > 1) {
        return "Multiple Locations";
      } else {
        return locationsValidInSelectedDateRange.find(
          (d) => d.id === appliedLocationFilters[0]
        ).name;
      }
    }
    return "No locations";
  }, [locationsValidInSelectedDateRange, appliedLocationFilters]);

  const handleDropdownToggle = (show) => {
    setMenuOpen(show);
  };

  useEffect(() => {
    if (!isMenuOpen && focusLocationFilters) {
      setTimeout(() => {
        toggleBtnRef.current?.click();
      }, 500);
    }
    if (isMenuOpen && focusLocationFilters) {
      dispatchAction(clearLocationFilterFocus());
    }
  }, [focusLocationFilters, isMenuOpen]);

  const toggleBtnRef = useRef();

  return (
    <Wrapper className="location-select-control">
      <LocationFilterProvider
        isMenuOpen={isMenuOpen}
        dropdownBtnRef={toggleRef}
      >
        <Dropdown onToggle={handleDropdownToggle} ref={toggleRef}>
          <Dropdown.Toggle ref={toggleBtnRef}>
            <img src={LocationPin} alt="location" /> <p>{toggleTitle}</p>
            <BsChevronDown width={"14px"} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MultiSelectMenu />
          </Dropdown.Menu>
        </Dropdown>
      </LocationFilterProvider>
    </Wrapper>
  );
};

export default LocationFilterDropdown;

const fadeInTop = keyframes`
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50);
    transform: translateY(-50);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  .dropdown {
    .dropdown-toggle::after {
      content: none;
    }
    .dropdown-toggle {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      > img {
        position: relative;
        width: 25px;
      }
      > p {
        margin-bottom: 0px;
        margin-left: 10px;
        margin-right: 1rem;
      }
      &.btn-primary {
        background-color: ${({ theme }) => theme.widget.background};
        border-color: ${({ theme }) => theme.widget.background};
        color: ${({ theme }) => theme.text};
        border-radius: 8px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .dropdown-menu {
      animation: ${fadeInTop} 0.2s;
      min-width: 350px;
      padding: 20px;
      border: 1px solid ${({ theme }) => theme.border_color};
      box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      .location-select__control {
        cursor: text;
        border: 1px solid ${({ theme }) => theme.border_color};
        &.location-select__control--is-focused {
          border-color: #0d8a6a;
          box-shadow: none;
        }
      }
      .location-select__menu {
        border-radius: 0px 0px 20px 20px;
        position: static;
        box-shadow: none;
        margin-bottom: 0;
        .hide-select-all {
          display: none;
        }
        .select_all_option {
          cursor: pointer;
          border-bottom: 1px solid ${({ theme }) => theme.border_color};
          padding: 0.5rem 0;
          &:hover {
            color: #fff;
            background-color: #3aa98d;
          }
        }
        .dropdown-actions {
          display: flex;
          margin-top: 0.5rem;
          .btn {
            padding: 5px 0;
            width: 100%;
            height: 40px;
            font-weight: 500;
            font-size: 14px;
            border-radius: 4px;
            &:first-child {
              margin-right: 10px;
            }
          }
          .cancel-btn {
            border: 1px solid #1f1f1f;
            background-color: ${({ theme }) => theme.body};
            color: ${({ theme }) => theme.text};
          }
          .submit-btn {
            border: none;
            background-color: rgb(13, 138, 106);
          }
        }
        .location-select__menu-list {
          padding: 0;
          .location-select__option {
            cursor: pointer;
            &:hover {
              color: #fff;
              background-color: #3aa98d;
            }
            &.location-select__option--is-selected {
              color: ${({ theme }) => theme.dropdown.color};
              background: ${({ theme }) => theme.dropdown.selected};
              &:hover {
                color: #fff;
                background-color: #3aa98d;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .dropdown {
      .dropdown-toggle {
        > img {
          width: 20px;
        }
        > p {
          font-size: 14px;
        }
        &.btn-primary {
          border-radius: 8px;
          padding: 6px;
        }
      }
    }
  }
`;
