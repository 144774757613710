import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createWidgetFilterConfig } from "../../../helpers/utils";
import { RootState } from "../../../state/store";
import { WidgetProvider } from "../../widgetContext";
import WidgetErrorBoundary from "../../widgetErrorBoundary";
import SelectWidget from "../selectWidget";
import { useFetchAnalyticsWidgetData } from "../../../helpers/hooks/useFetchAnalyticsWidgetData";
import WidgetLockedMarketingContent from "../../widgetLockedMarketingContent";
import WidgetLockedUnauthorized from "../../widgetLockedUnauthorized";
import {
  ENTITY_LOCK_TYPE,
  MarketingContentState,
} from "../../../types/visibility";
import { format } from "date-fns";
import { ExtendedAMarketingModal } from "./extendedAMarketingModal";
import AiCommentaryView from "../../../features/ai/components/commentaryView";
import { useFetchAiSuggestions } from "../../../features/ai/hooks/useFetchAiSuggestions";
import { useExtendedAContext } from "../../../features/extendedAnalytics/hooks/useExtendedAContext";
import { isBrowserCompatibleForAi } from "../../../features/ai/helpers/utils";
import { ToastContext } from "../../../helpers/contexts/toastContext";
import { ToastContextType } from "../../../types/toast";
import { useExtAContext } from "../../../features/extendedAnalytics/hooks/useExtAContext";
import { useExtAConfigs } from "../../../features/extendedASSRM/hooks/useExtAConfigs";

const AnalyticsWidgetLoader = ({ dashboard, dashboardWidget, widget }) => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [widgetFilterConfig, setWidgetFilterConfig] = useState<any>({});
  const [enableExtendedAReport, setExtendedReportStatus] = useState(false);
  const { filters } = useSelector((state: RootState) => state);
  const [marketingContent, setMarketingContent] =
    useState<null | MarketingContentState>(null);
  const [widgetUnauthorized, setWidgetUnauthorized] = useState(false);
  const [isTimelessWidget, setIsTimelessWidget] = useState(false);
  const [showTimelessUI, setShowTimelessUI] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date());
  const [extendedAMarketingUrl, setExtendedAMarketingUrl] = useState(false);
  const [showExtendedAMarketingContent, setShowExtendedAMarketingContent] =
    useState(false);
  const [isAiSuggestionEnabled, setIsAiSuggestionEnabled] = useState(false);
  const [useExtASSRM, setUseExtASSRM] = useState(false);
  const { handleShowExtendedAReport } = useExtendedAContext();
  const { dialogHandler: extAReportDialog } = useExtAConfigs();
  const { showToast } = useContext(ToastContext) as ToastContextType;

  const {
    data: aiSuggestionData,
    error: aiSuggestionLoadError,
    isLoading: aiSuggestionLoading,
    reloadData: retryAiFetch,
    resetData: resetAiSuggestionData,
  } = useFetchAiSuggestions({
    dashboard,
    dashboardWidget,
    widgetUrl: widget.url,
    filters,
  });

  const addtlFilters = useRef([]);

  const {
    data,
    isValidating: isWidgetLoading,
    error: widgetLoadError,
    reloadData,
    getWidgetDataWithFilters,
    isInputRequired: widgetInputRequired,
    overrideMessage,
    toolTipMessage,
    filterUsedByWidget,
  } = useFetchAnalyticsWidgetData({
    dashboard,
    dashboardWidget,
    widgetUrl: widget.url,
    filters,
  });

  useEffect(() => {
    if (data && typeof data === "object") {
      setLastUpdatedTime(new Date());
      if ("widget_filter_config" in data) {
        setWidgetData(data.widget);
        setWidgetFilterConfig(createWidgetFilterConfig(data));
      }
      if ("extended_analytics" in data) {
        setExtendedReportStatus(data.extended_analytics);
      }
      if ("is_timeless_widget" in data) {
        let today = format(new Date(), "yyyy-MM-dd");
        let selectedDate = filters.dates[0];
        setIsTimelessWidget(data?.is_timeless_widget);
        setShowTimelessUI(today !== selectedDate);
      }
      if ("extended_analytics_visibility" in data) {
        let lockType = data.extended_analytics_visibility
          if(lockType === ENTITY_LOCK_TYPE.MARKETING_MESSAGE) {
            setExtendedAMarketingUrl(data?.extended_analytics_marketing_html_url);
          }
      }
      if ("ai_assistant" in data) {
        setIsAiSuggestionEnabled(data["ai_assistant"]);
      }
      if ("extended_analytics_ssrm" in data) {
        setUseExtASSRM(data["extended_analytics_ssrm"]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (widgetLoadError) {
      let errorStatusCode = widgetLoadError?.response?.status ?? null;
      let errorData = widgetLoadError?.response?.data ?? null;
      if (errorStatusCode === 423) {
        let lockType: ENTITY_LOCK_TYPE =
          errorData?.lock_type ?? ENTITY_LOCK_TYPE.UNAUTHORIZED;
        switch (lockType) {
          case ENTITY_LOCK_TYPE.MARKETING_MESSAGE:
            setMarketingContent({
              marketingHtmlUrl: errorData?.marketing_html_url,
            });
            break;
          case ENTITY_LOCK_TYPE.UNAUTHORIZED:
          case ENTITY_LOCK_TYPE.INVISIBLE:
            setWidgetUnauthorized(true);
            break;
          default:
            break;
        }
      }
    }
  }, [widgetLoadError]);

  const handleWidgetOnClick = () => {
    // TODO
  };

  const handleExtendedAClick = (addFilters) => {
    addtlFilters.current = addFilters;
    if (!extendedAMarketingUrl && enableExtendedAReport) {
      if (useExtASSRM) {
        extAReportDialog.showReport({ widgetUrl: widget.url, addFilters });
      } else {
        handleShowExtendedAReport({ widgetUrl: widget.url, addFilters });
      }
    } else {
      setShowExtendedAMarketingContent(true);
    }
  };

  const handleCloseExtendedAMarketingModal = () => {
    setShowExtendedAMarketingContent(false);
  };

  if (marketingContent) {
    return (
      <WidgetLockedMarketingContent url={marketingContent?.marketingHtmlUrl} />
    );
  }
  if (widgetUnauthorized) {
    return <WidgetLockedUnauthorized type="a_level" />;
  }

  const handleOpenAiAdvisor = () => {
    // Check if browser is compatible
    if (isBrowserCompatibleForAi()) {
      retryAiFetch();
    } else {
      showToast({
        message:
          "Your browser version isn't supported. Please update to the latest version to use AI Advisor.",
        type: "error",
        config: {
          position: "top-center",
        },
      });
    }
  };

  const handleCloseAiAdvisor = () => {
    resetAiSuggestionData();
  }

  return (
    <WidgetErrorBoundary url={widget.url} type={widget.type}>
      {extendedAMarketingUrl ? (
        <ExtendedAMarketingModal
          show={showExtendedAMarketingContent}
          closeModal={handleCloseExtendedAMarketingModal}
          url={extendedAMarketingUrl}
        />
      ) : null}
      {isAiSuggestionEnabled ? (
        <AiCommentaryView
          show={Boolean(aiSuggestionData)}
          closeModal={handleCloseAiAdvisor}
          data={aiSuggestionData}
        />
      ) : null}
      <WidgetProvider
        widgetType={widget.type}
        widgetData={widgetData}
        widgetUrl={widget.url}
        widgetOnClick={handleWidgetOnClick}
        childWidgets={[]}
        widgetLoading={isWidgetLoading}
        widgetLoadError={widgetLoadError}
        widgetInputRequired={widgetInputRequired}
        widgetFilterConfig={widgetFilterConfig}
        overrideWidgetContent={overrideMessage}
        getWidgetDataWithFilters={getWidgetDataWithFilters}
        enableExtendedAReport={enableExtendedAReport}
        onExtendedAClick={handleExtendedAClick}
        refreshData={reloadData}
        isTimelessWidget={isTimelessWidget && showTimelessUI}
        lastUpdatedTime={lastUpdatedTime}
        isAiSuggestionEnabled={isAiSuggestionEnabled}
        isAiSuggestionLoading={aiSuggestionLoading}
        isAiSuggestionLoadError={aiSuggestionLoadError}
        getAiSuggestion={handleOpenAiAdvisor}
        toolTipMessage={toolTipMessage}
        filterUsedByWidget={filterUsedByWidget}>
        <SelectWidget widgetType={widget.type} />
      </WidgetProvider>
    </WidgetErrorBoundary>
  );
};

export default AnalyticsWidgetLoader;
