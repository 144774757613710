import styled from "styled-components";
import {
  CUSTOM_COLOR_SCALE,
  CUSTOM_COLOR_SCALE_INVERT,
} from "../../../helpers/constants";

export const ColorScaleLegend = ({
  scaleTitle,
  minValue,
  maxValue,
  invertScale = false,
}) => {
  return (
    <Wrapper>
      <div className="scale-title">{scaleTitle}</div>
      <div
        className={`color-scale ${invertScale ? "color-scale-invert" : ""}`}
      ></div>
      <div className="scale-values">
        <span>{formatNumber(minValue)}</span>
        <span>{formatNumber(maxValue)}</span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.widget.background};
  z-index: 1;
  border-radius: 20px 0 0 0;
  padding: 15px;
  bottom: 0px;
  right: 0px;
  height: 155px;
  .scale-title {
    font-size: 18px;
    margin-bottom: 12px;
    border-bottom: 0.5px solid #eeeeee;
    padding-bottom: 2px;
  }
  .color-scale {
    margin-top: 30px;
    width: 230px;
    height: 16px;
    border-radius: 20px;
    background: linear-gradient(to right, ${CUSTOM_COLOR_SCALE.join(",")});
    &.color-scale-invert {
      background: linear-gradient(
        to right,
        ${CUSTOM_COLOR_SCALE_INVERT.join(",")}
      );
    }
  }
  .scale-values {
    display: flex;
    justify-content: space-between;
  }
`;
