import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../state/store";
import WidgetErrorBoundary from "../../widgetErrorBoundary";
import SelectWidget from "../selectWidget";
import { useFetchAnalyticsWidgetData } from "../../../helpers/hooks/useFetchAnalyticsWidgetData";
import { GroupWidgetMeta } from "../../../types/widgets";
import { AnalyticsGroupWidgetContext } from "../../../helpers/contexts/analyticsGroupWidgetContext";
import AiCommentaryView from "../../../features/ai/components/commentaryView";
import { useFetchAiSuggestions } from "../../../features/ai/hooks/useFetchAiSuggestions";
import { useExtendedAContext } from "../../../features/extendedAnalytics/hooks/useExtendedAContext";
import { isBrowserCompatibleForAi } from "../../../features/ai/helpers/utils";
import { ToastContextType } from "../../../types/toast";
import { ToastContext } from "../../../helpers/contexts/toastContext";
import { useExtAConfigs } from "../../../features/extendedASSRM/hooks/useExtAConfigs";

interface Props {
  widgetMeta: GroupWidgetMeta;
}

const AnalyticsGroupWidgetLoader = ({ widgetMeta }: Props) => {
  const [enableExtendedAReport, setExtendedReportStatus] = useState(false);
  const { filters } = useSelector((state: RootState) => state);
  const [widgetUrl, setWidgetUrl] = useState(null);
  const [isAiSuggestionEnabled, setIsAiSuggestionEnabled] = useState(false);
  const [useExtASSRM, setUseExtASSRM] = useState(false);
  const { handleShowExtendedAReport } = useExtendedAContext();
  const { dialogHandler: extAReportDialog } = useExtAConfigs();
  const { showToast } = useContext(ToastContext) as ToastContextType;

  const routeMatch = useParams();
  const {
    data: widgetData,
    error: apiError,
    isValidating: isLoading,
    reloadData: fetchData,
  } = useFetchAnalyticsWidgetData({
    dashboard: routeMatch?.dashboard,
    dashboardWidget: routeMatch?.widget,
    filters,
    widgetUrl,
  });

  const {
    data: aiSuggestionData,
    error: aiSuggestionLoadError,
    isLoading: aiSuggestionLoading,
    reloadData: retryAiFetch,
    resetData: resetAiSuggestionData,
  } = useFetchAiSuggestions({
    dashboard: routeMatch.dashboard,
    dashboardWidget: routeMatch.widget,
    widgetUrl,
    filters,
  });

  useEffect(() => {
    if (widgetData) {
      if ("ai_assistant" in widgetData) {
        setIsAiSuggestionEnabled(widgetData["ai_assistant"]);
      }
      if ("extended_analytics_ssrm" in widgetData) {
        setUseExtASSRM(widgetData["extended_analytics_ssrm"]);
      }
    }
  }, [JSON.stringify(widgetData)]);

  const handleExtendedAReport = () => {
    if (enableExtendedAReport) {
      if (useExtASSRM) {
        extAReportDialog.showReport({ widgetUrl });
      } else {
        handleShowExtendedAReport({ widgetUrl });
      }
    }
  };

  const handleOpenAiAdvisor = () => {
    // Check if browser is compatible
    if (isBrowserCompatibleForAi()) {
      retryAiFetch();
    } else {
      showToast({
        message:
          "Your browser version isn't supported. Please update to the latest version to use AI Advisor.",
        type: "error",
        config: {
          position: "top-center",
        },
      });
    }
  };

  const handleCloseAiAdvisor = () => {
    resetAiSuggestionData();
  }

  return (
    <WidgetErrorBoundary>
      {isAiSuggestionEnabled ? (
        <AiCommentaryView
          show={Boolean(aiSuggestionData)}
          closeModal={handleCloseAiAdvisor}
          data={aiSuggestionData}
        />
      ) : null}
      <AnalyticsGroupWidgetContext.Provider
        value={{
          apiError,
          fetchData,
          isLoading,
          widgetData,
          widgetMeta,
          setWidgetUrl,
          onExtendedAClick: handleExtendedAReport,
          enableExtendedAReport: enableExtendedAReport,
          setEnableExtendedReport: setExtendedReportStatus,
          isAiSuggestionEnabled: isAiSuggestionEnabled,
          isAiSuggestionLoading: aiSuggestionLoading,
          isAiSuggestionLoadError: aiSuggestionLoadError,
          getAiSuggestion: handleOpenAiAdvisor,
        }}>
        <SelectWidget widgetType={widgetMeta?.type} />
      </AnalyticsGroupWidgetContext.Provider>
    </WidgetErrorBoundary>
  );
};

export default AnalyticsGroupWidgetLoader;
