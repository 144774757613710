import { rmsApi } from "../../../state/axios";

const fetchViews = async (payload) => {
  try {
    let result = await rmsApi.get(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views`
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveNewView = async (payload) => {
  try {
    let result = await rmsApi.post(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views`,
      payload
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateView = async (
  id: string,
  payload: {
    name: string;
    payload: any;
    dashboard: string;
    widget: string;
    url: string;
  }
) => {
  try {
    let result = await rmsApi.patch(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views/${id}`,
      payload
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteView = async (
  id,
  payload: { dashboard: string; widget: string; url: string }
) => {
  try {
    let result = await rmsApi.delete(
      `/api/v1/dar/${payload.dashboard}/${payload.widget}/${payload.url}/extended/views/${id}`
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export { fetchViews, saveNewView, updateView, deleteView };
